import React from 'react'
import styles from '../../styles/components/blog/blog-sections.module.scss'

import IconQuote from '../../images/icon-quote.svg'

const BlogQuoteSection = props => {
  const data = props.data
  const quote = data.quote.childMarkdownRemark.html
  return (
    <div className={styles.container}>
      <IconQuote alt="" className={styles.quoteIcon} />
      <div className={styles.quoteTextContainer}>
        <div dangerouslySetInnerHTML={{ __html: quote }} />
        <div className={styles.quoteSource}>{data.source}</div>
        <div className={styles.quoteSourceSubtext}>{data.sourceSubtext}</div>
      </div>
    </div>
  )
}

export default BlogQuoteSection
