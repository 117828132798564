import React from 'react'
import Img from 'gatsby-image'
import styles from '../../styles/components/blog/blog-sections.module.scss'
import classNames from 'classnames/bind'
let cx = classNames.bind(styles)

const BlogImageSection = props => {
  const data = props.data
  const isFullWidth = props.data.isFullWidth
  {
    /*const isFullWidth = true*/
  }
  const imageContainerStyle = cx(
    { fullImageContainer: isFullWidth },
    { inlineImageContainer: !isFullWidth }
  )

  return (
    <div className={styles.container}>
      <div className={imageContainerStyle}>
        <Img fluid={data.image.fluid} alt={data.caption} />
      </div>
      <div className={styles.imageCaption}>{data.caption}</div>
    </div>
  )
}

export default BlogImageSection
