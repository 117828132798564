import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../styles/components/blog/blog-share.module.scss'

import IconFB from '../../images/logos/icon-facebook.svg'
import IconTwitter from '../../images/logos/icon-twitter.svg'
import IconLinkedIn from '../../images/logos/icon-linkedin.svg'
import IconEmail from '../../images/logos/icon-email.svg'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share'

const BlogShare = ({ socialConfig }) => (
  <div className={styles.container}>
    <FacebookShareButton
      url={socialConfig.url}
      className={styles.shareContainer}
      quote={socialConfig.title}
    >
      <IconFB alt="Share on Facebook" />
    </FacebookShareButton>
    <TwitterShareButton
      url={socialConfig.url}
      className={styles.shareContainer}
      title={socialConfig.title}
    >
      <IconTwitter alt="Share on Twitter" />
    </TwitterShareButton>
    <LinkedinShareButton
      url={socialConfig.url}
      className={styles.shareContainer}
    >
      <IconLinkedIn alt="Share on LinkedIn" />
    </LinkedinShareButton>
    <EmailShareButton url={socialConfig.url} className={styles.shareContainer}>
      <IconEmail alt="Share with Email" />
    </EmailShareButton>
  </div>
)

BlogShare.propTypes = {
  socialConfig: PropTypes.shape({
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default BlogShare
