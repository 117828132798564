module.exports = {
  siteTitle: '15 Finches',
  siteTitleAlt: '15 Finches Studio',
  publisher: 'Weston Baker Creative Group',
  siteDescription: `15 Finches helps build and accelerate startups. We're a team within your team.
We aren't your typical agency, consultant or accelerator.`,
  siteUrl: 'https://www.15finches.com',
  userTwitter: '@15finches',
  shareImage: '/logos/share.jpg',
  shareImageWidth: 900,
  shareImageHeight: 900,
  siteLogo: '/logos/favicon.png',
}
