import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styles from '../styles/templates/blog-post.module.scss'

import Layout from '../components/layout'
import SEO from '../components/SEO'

import BlogTextSection from '../components/blog/blogTextSection'
import BlogQuoteSection from '../components/blog/blogQuoteSection'
import BlogImageSection from '../components/blog/blogImageSection'
import BlogHighlightSection from '../components/blog/blogHighlightSection'
import BlogShare from '../components/blog/blogShare'
import EmailCollect from '../components/emailCollect'
import CaseStudyLinks from '../components/CaseStudyLinks'
import Footer from '../components/footer'

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isMobile: null, activeCategory: 'All' }
    this.articleRef = React.createRef()
  }

  componentDidMount() {
    if (window.innerWidth <= 768) {
      this.setState({ isMobile: true })
    }
    window.onresize = () => {
      this.setState(prevState => ({
        isMobile: window.innerWidth <= 768,
      }))
    }
  }

  render() {
    const data = this.props.data
    const post = this.props.data.contentfulBlogPost
    const previous = this.props.pageContext.prev
    const next = this.props.pageContext.next
    const blogPostNode = data.contentfulBlogPost
    return (
      <Layout headerScrollbar headerScrollRef={this.articleRef}>
        <SEO
          postNode={blogPostNode}
          blogPostSEO
          pagePath={`blog/${post.slug}`}
        />
        <div className={styles.container} ref={this.articleRef}>
          <div className={styles.backgroundBox}></div>
          <div className={styles.heroContainer}>
            <div className={styles.headerContainer}>
              <div className={styles.categoryText}>
                {post.categories &&
                  post.categories.map((category, index) => {
                    return category + '\xa0\xa0\xa0' //three nbsp
                  })}
              </div>
              <div className={styles.titleText}>{post.title}</div>
              {post.author && (
                <div className={styles.authorText}>{post.author}</div>
              )}
            </div>
            <div className={styles.heroImageContainer}>
              <Img fluid={post.heroImage.fluid} alt={post.title} />
            </div>
            {post.heroDescription && (
              <div className={styles.heroImageText}>{post.heroDescription}</div>
            )}
          </div>
          <div className={styles.sectionContainer}>
            {post.sections &&
              post.sections.map((section, index) => {
                switch (section.__typename) {
                  case 'ContentfulBlogTextSection':
                    return <BlogTextSection data={section} key={index} />
                  case 'ContentfulBlogQuoteSection':
                    return <BlogQuoteSection data={section} key={index} />
                  case 'ContentfulBlogImageSection':
                    return <BlogImageSection data={section} key={index} />
                  case 'ContentfulBlogHighlightSection':
                    return <BlogHighlightSection data={section} key={index} />
                  default:
                    return null
                }
              })}
            <BlogShare
              socialConfig={{
                url: `${data.site.siteMetadata.siteUrl}/lab/${post.slug}/`,
                title: post.title,
              }}
            />
          </div>

          <EmailCollect />
        </div>
        <CaseStudyLinks previous={previous} next={next} blogPost={true} />
        <Footer isFullHeight={false} whiteBackground={true} />
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      date(formatString: "MMMM D, YYYY")
      author
      categories
      heroImage {
        fluid(maxWidth: 915, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
        ogimg: resize(width: 1800) {
          src
          width
          height
        }
      }
      # heroDescription
      metaDescription {
        internal {
          content
        }
      }
      sections {
        ... on Node {
          __typename
          ... on ContentfulBlogTextSection {
            text {
              childMarkdownRemark {
                html
              }
            }
            actionText
            actionLink
          }
          ... on ContentfulBlogQuoteSection {
            quote {
              childMarkdownRemark {
                html
              }
            }
            source
            sourceSubtext
          }
          ... on ContentfulBlogImageSection {
            isFullWidth
            caption
            image {
              fluid(maxWidth: 768, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulBlogHighlightSection {
            title
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`
