import React from 'react'
import styles from '../../styles/components/blog/blog-sections.module.scss'

import RightArrowBlue from '../../images/right-arrow-blue.svg'

const BlogTextSection = props => {
  const data = props.data
  const text = data.text.childMarkdownRemark.html
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>
      {data.actionText && (
        <a href={data.actionLink} className={styles.linkContainer}>
          {data.actionText}
          <RightArrowBlue alt="arrow" />
        </a>
      )}
    </div>
  )
}

export default BlogTextSection
