import React from 'react'
import styles from '../../styles/components/blog/blog-sections.module.scss'

const BlogHighlightSection = props => {
  const data = props.data
  const body = data.body.childMarkdownRemark.html
  return (
    <div className={styles.container}>
      <div className={styles.highlightContainer}>
        <div className={styles.highlightTitle}>{data.title}</div>
        <div className={styles.highlightBody}>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
    </div>
  )
}

export default BlogHighlightSection
