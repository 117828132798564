import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames/bind'
import styles from '../styles/components/case-study-links.module.scss'

import leftBlueArrow from '../images/left-arrow-blue.png'
import rightBlueArrow from '../images/right-arrow-blue.png'
import blueArrow from '../images/blue-arrow-svg.svg'

import SpinningFinch from './spinningFinch'

let cx = classnames.bind(styles)

const CaseStudyLinks = props => {
  const blogPost = props.blogPost
  let linkTitle = cx({
    blogPostTitle: blogPost,
    caseStudyTitle: !blogPost,
  })
  return (
    <div className={styles.container}>
      <div className={styles.circleIconGroup}>
        <SpinningFinch />
      </div>
      {props.previous && (
        <div className={styles.prevContainer}>
          <Link
            style={{ textDecoration: 'none' }}
            to={
              blogPost
                ? `/lab/${props.previous.slug}/`
                : `/${props.previous.slug}/`
            }
          >
            <img className={styles.arrow} src={leftBlueArrow} alt="" />
            {props.blogPost ? 'Previous Post' : 'Previous Project'} &nbsp;
          </Link>
          <div className={linkTitle}>
            {props.previous.slug.replace(/-/g, ' ')}
          </div>
        </div>
      )}
      {props.next && (
        <div className={styles.nextContainer}>
          <Link
            style={{ textDecoration: 'none' }}
            to={blogPost ? `/lab/${props.next.slug}/` : `/${props.next.slug}/`}
          >
            <div className={styles.link}>
              &nbsp; {props.blogPost ? 'Next Post' : 'Next Project'}{' '}
              <img className={styles.arrow} src={rightBlueArrow} alt="" />
            </div>
          </Link>
          <div className={linkTitle}>{props.next.slug.replace(/-/g, ' ')}</div>
        </div>
      )}
    </div>
  )
}

export default CaseStudyLinks
